
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';

export default defineComponent({
    components: { FormsReportTypes },
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            item: undefined as any,
            isAddCommentsModal: false,
            commentsParams: {
                filter: '',
                page: 1,
                sortBy: 'created_at',
                orderBy: 'desc',
            },
            newComment: {
                translations: {
                    comment: {},
                },
            },
            comments: {
                data: [],
                meta: {},
            },
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
        this.getComments();
    },
    methods: {
        getItem(id: string): void {
            this.get(`infringement-categories/${id}`).then(({ data }: any) => {
                this.item = data.data;
                (this.$refs.form as any).setValues(this.item);
            });
        },
        saveItem(): void {
            this.put(`infringement-categories/${this.$route.params.id}`, {
                ...this.item,
                forms_report_types: this.item.forms_report_types.map((f) => f.id),
                name: this.item.translations.name,
            }).then(() => {
                this.$router.push({ name: 'infringements.categories.list' });
                this.$emit('reload');
            });
        },
        addComment() {
            this.post(`infringement-categories/${this.$route.params.id}/comments`, {
                ...this.newComment,
                comment: this.newComment.translations.comment,
            })
                .then(() => {
                    this.getComments();
                    this.isAddCommentsModal = false;
                });
        },
        getComments(page: number = 1) {
            this.commentsParams.page = page;
            this.get(`infringement-categories/${this.$route.params.id}/comments`, {
                params: {
                    ...this.commentsParams,
                },
            })
                .then(({ data }) => {
                    this.comments = data;
                });
        },
        saveComment(data:any): void {
            this.put(`infringement-categories/${this.$route.params.id}/comments/${data.id}`, {
                ...data,
                comment: data.translations.comment,
            })
                .then(() => {
                    this.getComments();
                    this.isAddCommentsModal = false;
                });
        },
        deleteComment(id: string): void {
            this.get(`infringement-categories/${this.$route.params.id}/comments/${id}`)
                .then(() => {
                    this.getComments();
                });
        },
    },
});
